<template>
  <div class="es-login">
    <SelectDomainDialog v-model="isSelectDomainDialogOpened"/>
    <!-- image and title -->
    <div class="es-login__left">
      <img class="es-login__banner" src="/banner-white.svg" alt="bannière eDocSign powered by Silae"/>
      <div class="es-login__container eui-u-text-white">
        <h2 class="es-login__display --faded">{{ $t('login.sign') }}</h2>
        <h2 class="es-login__display" v-html="$t('login.text')"></h2>
      </div>
      <img class="es-login__logo-cover" src="/logo_white.svg"/>
    </div>
    <!-- login part -->
    <div class="es-login__right">
      <div class="es-login__container">
        <EuiHeading :level="2" class="es-login__title">{{ $t('login.title') }}</EuiHeading>
        <form @submit.prevent="doLogin" class="es-login__form">
          <EuiTextField :label="$t('login.username')"
                        class="eui-u-mb-4"
                        adaptSize
                        name="login"
                        v-model.trim="auth.login"
                        required
                        autocomplete="username"/>
          <EuiTextField :label="$t('login.password')"
                        adaptSize
                        name="password"
                        type="password"
                        v-model.trim="auth.password"
                        required
                        autocomplete="current-password"/>
          <EuiButton variant="raised"
                     class="es-login__main-button eui-u-mt-5"
                     color="primary"
                     type="submit">
            {{ $t('login.button') }}
          </EuiButton>
        </form>

        <!-- microsoft login -->
        <div class='es-separator'>
          <hr class='es-separator__line'>
          <h1 class='es-separator__text'>{{ $t('login.separator.text') }}</h1>
          <hr class='es-separator__line'>
        </div>

        <button class="es-accountChoice__button" @click="setConnexionMode('microsoft')" :disabled="errorOAuth">
          <img class="es-accountChoice__logo" src="/microsoft_logo.png" alt="logo Microsoft">
          <p>{{ $t('accountChoice.microsoft.text') }}</p>
        </button>

      </div>
      <div class="es-login__right__footer">
        <p class="es-login__copyright">{{$t('login.copyright')}} · <a target="_blank" href="https://support.silae.fr/hc/fr/articles/20058297048978-CGU-CGV-Conditions-g%C3%A9n%C3%A9rales-d-utilisation-et-de-vente-de-l-application-eDocSign-Version-1-1-du-30-06-2021">{{$t('login.confidentialityAndConditions')}}</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

const mode = Object.freeze({
  EDOCSIGN: 'edocsign',
  MICROSOFT: 'microsoft',
});

export default {
  name: 'Login',
  props: {
    login: {
      type: String,
      required: false,
      default: undefined,
    },
    password: {
      type: String,
      required: false,
      default: undefined,
    },
    errorOAuth: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  components: {
    SelectDomainDialog: () => import(/* webpackPrefetch: true */ '@/components/dialogs/SelectDomain'),
  },
  data() {
    return {
      auth: {
        login: '',
        password: '',
      },
      expiredMessageIsVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      session: 'ModuleEdocSign/auth/session',
      isDomainSelectable: 'ModuleEdocSign/auth/isDomainSelectable',
    }),
    isSelectDomainDialogOpened: {
      get() {
        return this.$store.state.application.showSelectDomainDialog;
      },
      set(value) {
        if (value === true) {
          this.$store.commit('application/SHOW_SELECT_DOMAIN');
        } else {
          this.$store.commit('application/CLOSE_SELECT_DOMAIN');
        }
      },
    },
  },
  methods: {
    ...mapActions({
      setLastBreadcrumb: 'application/setLastBreadcrumb',
      storeLogin: 'ModuleEdocSign/auth/login',
      showSelectDomainDialog: 'application/showSelectDomainDialog',
    }),
    goToHelp() {
      window.open(this.$t('button.help.link'), '_blank').focus();
    },
    doLogin() {
      this.setConnexionMode('edocsign');
      this.storeLogin(this.auth)
        .then(() => {
          console.log('storeLogin');
          if (this.session.guest) {
            if (this.$route.name === 'gAccessLogin') {
              this.$router.push({ name: 'guestSignworkflows.access' });
            }
            this.$router.push({ name: 'guestSignworkflows' });
          } else {
            if (this.isDomainSelectable) {
              this.showSelectDomainDialog();
            } else {
              this.$router.push({ name: 'dashboard' });
            }
          }
        });
    },
    setConnexionMode(value) {
      localStorage.setItem('connexionMode', value);
      if (value === mode.MICROSOFT) {
        const redirectUri = `${window.location.protocol}%2F%2F${window.location.host}%2Fauto-login-ms`;
        window.location = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&client_id=b5cc1d67-5b02-41c6-8917-d806a9eda1c0&redirect_uri=${redirectUri}&scope=openid%20User.Read`;
      }
    },
  },
  mounted() {
    if (this.login && this.password) {
      this.auth.login = this.login;
      this.auth.password = this.password;
    }

    // ms login
    if (this.errorOAuth === 405) {
      this.pushAlert({
        message: this.$i18n.t('login.warning.errorOauth'),
        type: 'error',
      });
    } else if (this.errorOAuth === 500) {
      this.pushAlert({
        message: this.$i18n.t('login.warning.no-ms-account'),
        type: 'error',
      });
    } else if (this.errorOAuth === 501) {
      this.pushAlert({
        message: this.$i18n.t('login.warning.account-offer-expired'),
        type: 'error',
      });
    } else if (this.errorOAuth === 200) {
      this.pushAlert({
        message: this.$i18n.t('login.warning.no-user-with-requested-email'),
        type: 'error',
      });
    }
  },
};
</script>
